import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import OnlineBanking from "../img/online_banking.png";
import CashPayments from "../img/cash_payments.png";
import CreditCard from "../img/credit_card.jpg";
import Spei from "../img/spei.png";
import OxxoPay from "../img/oxxo_pay.png";
import Oxxo from "../img/oxxo.png";
import Paynet from "../img/paynet.png";
import Todito from "../img/todito.png";
import Efecty from "../img/efecty.png";
import PSE from "../img/pse.png";
import Khipu from "../img/khipu.png";
import OnlineBankingM from "../img/online_mobile.png";
import CashPaymentsM from "../img/cash_mobile.png";
import EfectyM from "../img/efecty_mobile.png";
import PseM from "../img/pse_mobile.png";
import KhipuM from "../img/khipu_mobile.png";
import ToditoM from "../img/todito_mobile.png";
import ToditoCash from "../img/todito_cash.png";
import WalletBra from "../img/wallet_bra2.png";
// import Paypal from "../img/paypal.png";
import Mercadopago from "../img/paypal.png";
import Ame from "../img/ame.png";
import Picpay from "../img/picpay.png";
import Boleto from "../img/boleto.png";
import WalletChl from "../img/wallet_chl2.png";
import WalletChl2 from "../img/wallet_chl2.png";
import Mach from "../img/mach.png";
import Vita from "../img/vita.png";
import Chek from "../img/chek.png";
import DepositExpress from "../img/deposit_express6.svg";
import Lottery from "../img/lottery.png";
import Pix from "../img/pix.png";
import Paypal from "../img/paypal.png";
import Itau from "../img/itau.png";
import BancoDoBrasil from "../img/banco-do-brasil.png";
import Bradesco from "../img/bradesco.png";
import Caixa from "../img/caixa.png";
import TpagaLogo from "../img/tpaga_logo.jpg";
import Tpaga from "../img/tpaga.jpg";
import PagoEfectivo from "../img/pago_efectivo.png";
import PeScotia from "../img/pe_scotiabank.jpg";
import PePambo from "../img/pe_pambo.jpg";
import PeWu from "../img/pe_wu.jpg";
import PeBanbif from "../img/pe_banbif.jpg";
import PeBbva from "../img/pe_bbva.jpg";
import PeBcp from "../img/pe_bcp.jpg";
import PeCaja from "../img/pe_caja.jpg";
import PeDisashop from "../img/pe_disashop.jpg";
import PeInterbank from "../img/pe_interbank.jpg";
import PeKasnet from "../img/pe_kasnet.jpg";
import PeCellPower from "../img/pe_cellpower.jpg";
import PeGanaste from "../img/pe_ganaste.jpg";
import PeMoneyGram from "../img/pe_moneygram.jpg";
import PeNiuBiz from "../img/pe_niubiz.jpg";
import PePichinca from "../img/pe_pichinca.jpg";
import PeRedDigit from "../img/pe_reddigit.jpg";
import PeBanbifWallet from "../img/banbifwallet.png";
import PeBbvaWallet from "../img/bbvawallet.png";
import InterbankWallet from "../img/interbankwallet.png";
import LigoWallet from "../img/ligowallet.png";
import PlinWallet from "../img/plinwallet.png";
import ScotiabankWallet from "../img/scotiabankwallet.png";
import TunkiWallet from "../img/tunkiwallet.png";
import YapeWallet from "../img/yapewallet.png";
import Codi from "../img/codi.png";
import CodiM from "../img/codiM.png";
import Klap from "../img/klap.png";
import Gana from "../img/gana.png";
import Sured from "../img/sured.png";
import Pago46 from "../img/pago46.png";
import PagoFacil from "../img/pago_facil.png";
import RapiPago from "../img/rapi_pago.png";
import Bancolombia from "../img/bancolombia.png";
import Daviplata from "../img/daviplata.png";
import Nequi from "../img/nequi.png";
import Rapipay from "../img/rapipay.png";
import Movii from "../img/movii.png";
import Dale from "../img/dale.png";
import Baloto from "../img/baloto.png";
import BNCR from "../img/bncr.png";
import OpenFinance from "../img/openfinance.svg";
import Tupay from "../img/tupay-channels.png";
import Wallet from "../img/wallet.svg";
import Webpay from "../img/webpay.png";

import i18n from "../language/i18n";
import uuid from "react-uuid";

import Crypto from "../img/crypto.png";
import Btc from "../img/btc.jpg";
import BtcLight from "../img/btclight.png";
import Eth from "../img/eth.png";
import Usdc from "../img/usdc.png";
import UsdtErc from "../img/usdt_erc.png";
import UsdtTrc from "../img/usdt_trc.png";
import Binance from "../img/binance.png";

// in case success, fail, other these are not goint to redirect to /result
export const checkoutStatusExeptions = ["PSE-ACH"];

// method-channel pair for /checkout page
const checkoutConfig = [
  "BankTransfer-Monnet",
  "Cash-Monnet",
  "Cash-SafetyPay",
  "BankTransfer-SafetyPay",
  "SPEI-OpenPay",
  "SPEI-STP",
  "SPEI-Banwire",
  "SPEI-OPM",
  "Cash-Banwire",
  "Cash-OpenPay",
  "OXXO-Banwire",
  "OXXO-CardPay",
  "OXXO-Localpayment",
  "Wallet-Mach",
  "Wallet-Vita",
  "Wallet-AME",
  "Wallet-AlipayCN",
  "Wallet-AlipayHK",
  "Wallet-GCash",
  "Wallet-KakaoPayAlipay",
  "Wallet-TNG",
  "Wallet-TrueMoney",
  "Wallet-TPaga",
  "Wallet-RabbitLinePay",
  "Boleto-BS2",
  "BoletoRapido-BS2",
  "PIX-BS2",
  "PIX-Bradesco",
  "PIX-Rendimento",
  "PIX-Nupay",
  "PIX-Genial",
  "DepositExpress-Levpay",
  "OTC-Xendit",
  "Cash-PayCash",
  "Lottery-Caixa",
  "OXXOPay-Conekta",
  "Baloto-PayValida",
  "Baloto-IGT",
  "SuRed-PayValida",
  "GANA-PayValida",
  "Bancolombia-PayValida",
  "KLAP-Multicaja",
  "Cash-Multicaja",
  "PlayersBankPIX-BS2",
  "BankTransfer-Localpayment",
  "PagoFacil-Localpayment",
  "Rapipago-Localpayment",
  "Transfiya-Movii",
  "Cash-Localpayment",
  "CoDi-STP",
  "BNCR-PayValida",
  "BNCR-PayCash",
  "Wallet-Tupay",
  "Crypto-TripleA",
  "PSE-ACH",
];

const channelConfig = (method, channel) => {
  let config = [
    "Cash-SafetyPay",
    "Cash-PayCash",
    "Cash-PagoEfectivo",
    "BankTransfer-PagoEfectivo",
    "BankTransfer-SafetyPay",
    "Khipu",
    "Wallet",
    "DepositExpress",
    "PagoEfectivo",
    "Crypto",
  ]; // methods that have channels to be choosen
  return config.indexOf(method) >= 0 || config.indexOf(method + "-" + channel) >= 0;
};

// fot wallets in MEX and BR
const walletLogo = {
  MEX: { pc: Todito, mobile: ToditoM },
  BRA: { pc: WalletBra, mobile: WalletBra },
  CHL: { pc: WalletChl2, mobile: WalletChl2 },
  COL: { pc: TpagaLogo, mobile: TpagaLogo },
  PER: { pc: Wallet, mobile: Wallet },
};

const walletChannels = {
  MEX: { Todito: ToditoCash },
  BRA: { AME: Ame, PicPay: Picpay, Mercadopago: Mercadopago, Paypal: Paypal },
  CHL: { Mach: Mach, Vita: Vita, Chek: Chek },
  COL: { TPaga: Tpaga, Daviplata: Daviplata, Rappipay: Rapipay, Dale: Dale, Movii: Movii, Nequi: Nequi },
  PER: { Tupay: Tupay },
  // HKG: { Alipay: Alipay },
  // PHL: { GCash: GcashAlipay, GcashXendit: Gcash, PaymayaXendit: Paymaya },
  // KOR: { KakaoPay: Kakaopay },
  // IDN: { DANA: Dana },
  // MYS: { TNG: Tng },
  // THA: { TrueMoney: Truemoney },
};

export const cryptoChannels = [
  {
    data: [{ logo: Btc, channel: "BTC" }],
    key: uuid(),
  },
  {
    data: [{ logo: Binance, channel: "BINANCE" }],
    key: uuid(),
  },
  {
    data: [{ logo: BtcLight, channel: "LNBC" }],
    key: uuid(),
  },
  {
    data: [{ logo: Eth, channel: "ETH" }],
    key: uuid(),
  },
  {
    data: [{ logo: Usdc, channel: "USDC" }],
    key: uuid(),
  },
  {
    data: [{ logo: UsdtErc, channel: "USDT" }],
    key: uuid(),
  },
  {
    data: [{ logo: UsdtTrc, channel: "USDT_TRC20" }],
    key: uuid(),
  },
];

const depositExpressChannels = [
  // { data: [{ logo: Itau, bank: "itau" }], key: uuid() },
  // { data: [{ logo: Santander, bank: "santander" }], key: uuid() },
  // { data: [{ logo: BancoDoBrasil, bank: "banco-do-brasil" }], key: uuid() },
  { data: [{ logo: Bradesco, bank: "bradesco" }], key: uuid() },
  { data: [{ logo: Caixa, bank: "caixa" }], key: uuid() },
];

const countryCode2TaxIdType = {
  BRA: ["CPF", "CNPJ"],
  MEX: ["RFC", "CURP"],
  CHL: ["RUT/RUN"],
  COL: ["NIT", "CC", "CE"],
  PER: ["DNI", "RUC"],
  ECU: ["RUC", "CE", "CI"],
  PAN: ["CIP"],
  CRI: ["CDI"],
  SLV: ["DUI"],
  GTM: ["DPI"],
  NIC: ["CIP"],
  ARG: ["DNI", "CUIT", "CUIL", "CDI"],
  URY: ["CI", "RUT"],
  BOL: ["NIT", "CI", "CE"],
  PRY: ["RUC", "CI"],
};

const latinCountries = [
  "MEX",
  "CHL",
  "COL",
  "PER",
  "ECU",
  "BRA",
  "PAN",
  "CRI",
  "SLV",
  "GTM",
  "NIC",
  "ARG",
  "URY",
  "BOL",
  "PRY",
];

const countryCode2lang = {
  BRA: "pt",
  HKG: "en",
  PHL: "en",
  KOR: "en",
  IDN: "en",
  MYS: "en",
  THA: "en",
};

const countryCode3to2 = {
  MEX: "MX",
  CHL: "CL",
  COL: "CO",
  PER: "PE",
  ECU: "EC",
  BRA: "BR",
  PAN: "PA",
  CRI: "CR",
  SLV: "SV",
  GTM: "GT",
  NIC: "NI",
  HKG: "HK",
  PHL: "PH",
  KOR: "KR",
  IDN: "ID",
  MYS: "MY",
  THA: "TH",
  CHN: "CN",
  KHM: "KH",
  SGP: "SG",
  SAU: "SA",
  ARE: "AE",
  KWT: "KW",
  QAT: "QA",
  OMN: "OM",
  IND: "IN",
  RUS: "RU",
  EGY: "EG",
  GBR: "GB",
  BHR: "BH",
  ARG: "AR",
  URY: "UY",
  BOL: "BO",
  PRY: "PY",
  AUS: "AU",
  POL: "PL",
  NZL: "NZ",
  CHE: "CH",
  CAN: "CA",
  USA: "US",
};

const formatCurrency = {
  BRL: "R$",
  MXN: "Mex$",
  RUB: "руб",
  USD: "US$",
  SGD: "SG$",
  CNY: "¥",
};

export const pagoEfectivoChannels = [
  {
    data: [
      { logo: YapeWallet },
      { logo: PlinWallet },
      { logo: PeBanbifWallet },
      { logo: PeBbvaWallet },
      { logo: InterbankWallet },
      { logo: LigoWallet },
      { logo: ScotiabankWallet },
      { logo: TunkiWallet },
      { logo: PeScotia },
      { logo: PePambo },
      { logo: PeWu },
      { logo: PeBanbif },
      { logo: PeBbva },
      { logo: PeBcp },
      { logo: PeCaja },
      { logo: PeDisashop },
      { logo: PeInterbank },
      { logo: PeKasnet },
      { logo: PeCellPower },
      { logo: PeGanaste },
      { logo: PeMoneyGram },
      { logo: PeNiuBiz },
      { logo: PePichinca },
      { logo: PeRedDigit },
    ],
    key: uuid(),
  },
];

export const pagoEfectivoBankChannels = [
  {
    data: [
      { logo: PeBbva },
      { logo: PeBcp },
      { logo: PeInterbank },
      { logo: PeCaja },
      { logo: PeScotia },
      { logo: PeBanbif },
      { logo: PePichinca },
    ],
    key: uuid(),
  },
];

export const pagoEfectivoCashChannels = [
  {
    data: [
      { logo: PeBbva },
      { logo: PeBcp },
      { logo: PeInterbank },
      { logo: PeScotia },
      { logo: PeCaja },
      { logo: PePambo },
      { logo: PeWu },
      { logo: PeDisashop },
      { logo: PeKasnet },
      { logo: PeCellPower },
      { logo: PeGanaste },
      { logo: PeMoneyGram },
      { logo: PeNiuBiz },
      { logo: PeRedDigit },
    ],
    key: uuid(),
  },
];

const useMethodConfig = (props) => {
  const { t } = useTranslation();
  const tradeInfo = useSelector((state) => state.tradeInfo);
  const region = tradeInfo?.app_info?.region;
  const isOnlyCreditCard = tradeInfo?.channel_info_list?.some?.((item) => {
    return item.method === "CreditCard" && item.channel === "Localpayment";
  });
  const methodConfig = {
    // Brazil
    Boleto: {
      info: {
        imageMobile: Boleto,
        image: Boleto,
        text: "Boleto",
      },
    },

    CreditCard: {
      info: {
        imageMobile: CreditCard,
        image: CreditCard,
        text: isOnlyCreditCard ? t("credit_card") : t("credit_debit_card"),
      },
    },
    DebitCard: {
      info: {
        imageMobile: CreditCard,
        image: CreditCard,
        text: t("debit_card"),
      },
    },
    DepositExpress: {
      info: {
        imageMobile: DepositExpress,
        image: DepositExpress,
        text: t("bank_deposit"),
      },
    },
    Lottery: {
      info: {
        imageMobile: Lottery,
        image: Lottery,
        text: t("payment_lottery"),
      },
    },
    PIX: {
      info: {
        imageMobile: Pix,
        image: Pix,
        text: "PIX",
      },
    },
    Wallet: {
      info: {
        imageMobile: walletLogo[region]?.mobile,
        image: walletLogo[region]?.pc,
        text: t("wallet"),
      },
    },
    SPEI: {
      info: {
        imageMobile: Spei,
        image: Spei,
        text: "SPEI",
      },
    },
    // Mexico
    Paynet: {
      info: {
        imageMobile: Paynet,
        image: Paynet,
        text: "Paynet",
      },
    },
    BankTransfer: {
      info: {
        imageMobile: OnlineBankingM,
        image: OnlineBanking,
        text: t("online_banking"),
      },
    },
    Cash: {
      info: {
        imageMobile: CashPaymentsM,
        image: CashPayments,
        text: t("cash_payments"),
      },
    },
    PSE: {
      info: {
        imageMobile: PseM,
        image: PSE,
        text: "PSE",
      },
    },
    Efecty: {
      info: {
        imageMobile: EfectyM,
        image: Efecty,
        text: "Efecty",
      },
      faq: [
        { q: t("efecty_faq1q"), a: t("efecty_faq1a") },
        { q: t("efecty_faq2q"), a: t("efecty_faq2a") },
        { q: t("efecty_faq3q"), a: t("efecty_faq3a") },
      ],
      video: [
        // {
        //   title: "Como pagar com banco online",
        //   source: "https://www.youtube.com/embed/74a9n3y2ClU",
        // },
      ],
    },
    Khipu: {
      info: {
        imageMobile: KhipuM,
        image: Khipu,
        text: "Khipu",
      },
      faq: [
        { q: t("khipu_faq1q"), a: t("khipu_faq1a") },
        { q: t("khipu_faq2q"), a: t("khipu_faq2a") },
        { q: t("khipu_faq3q"), a: t("khipu_faq3a") },
      ],
    },

    // BoletoRapido: {
    //   info: {
    //     imageMobile: BoletoRapidao,
    //     image: BoletoRapidao,
    //     text: "Boleto Rápido",
    //   },
    // },
    OXXO: {
      info: {
        imageMobile: Oxxo,
        image: Oxxo,
        text: t("oxxo"),
      },
    },
    PagoEfectivo: {
      info: {
        imageMobile: PagoEfectivo,
        image: PagoEfectivo,
        text: t("pago_method"),
      },
    },
    CoDi: {
      info: {
        imageMobile: CodiM,
        image: Codi,
        text: "CoDi",
      },
    },
    KLAP: {
      info: {
        imageMobile: Klap,
        image: Klap,
        text: "Efectivo Klap",
      },
    },
    Baloto: {
      info: {
        imageMobile: Baloto,
        image: Baloto,
        text: "Baloto",
      },
      faq: [
        {
          q: t("baloto_faq1q"),
          a: t("baloto_faq1a"),
        },
      ],
    },
    SuRed: {
      info: {
        imageMobile: Sured,
        image: Sured,
        text: "Su Red",
      },
    },
    GANA: {
      info: {
        imageMobile: Gana,
        image: Gana,
        text: "Gana",
      },
    },
    Pago46: {
      info: {
        imageMobile: Pago46,
        image: Pago46,
        text: "Pago46",
      },
    },
    PagoFacil: {
      info: {
        imageMobile: PagoFacil,
        image: PagoFacil,
        text: "Pago Facil",
      },
    },
    Rapipago: {
      info: {
        imageMobile: RapiPago,
        image: RapiPago,
        text: "Rapi Pago",
      },
    },
    Bancolombia: {
      info: {
        imageMobile: Bancolombia,
        image: Bancolombia,
        text: "Bancolombia",
      },
    },
    OXXOPay: {
      info: {
        imageMobile: OxxoPay,
        image: OxxoPay,
        text: "OXXO PAY",
      },
    },
    BNCR: {
      info: {
        imageMobile: BNCR,
        image: BNCR,
        text: "Banco Nacional de Costa Rica",
      },
    },
    OpenFinance: {
      info: {
        imageMobile: OpenFinance,
        image: OpenFinance,
        text: "Open Finance",
      },
    },
    Crypto: {
      info: {
        imageMobile: Crypto,
        image: Crypto,
        text: t("crypto"),
      },
    },
    WebPay: {
      info: {
        imageMobile: Webpay,
        image: Webpay,
        text: "Webpay",
      },
    },
  };
  return methodConfig;
};

export {
  useMethodConfig,
  channelConfig,
  countryCode3to2,
  walletChannels,
  depositExpressChannels,
  countryCode2lang,
  latinCountries,
  checkoutConfig,
  formatCurrency,
  countryCode2TaxIdType,
};
